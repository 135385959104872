<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.edit_m", {
                m: $t("message.infoForCabinet"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item prop="shipping_price_info" :label="$t('message.shipping_price_info')">
                        <ckeditor :editor="editor" v-model="form.shipping_price_info" :config="editorConfig"></ckeditor>
                      </el-form-item>      
                    </el-col>
                    <el-col :span="24">
                      <el-form-item prop="delivery_info" :label="$t('message.delivery_info')">
                        <ckeditor :editor="editor" v-model="form.delivery_info" :config="editorConfig"></ckeditor>
                      </el-form-item>      
                    </el-col>
                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import Editor from 'ckeditor5-custom-build/build/ckeditor';

export default {
    mixins: [form, drawer, show],
    name: "infoForCabinet",
    data() {
        return {
          editor: Editor,
          editorConfig: {
              // The configuration of the editor.
          }
        };
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "infoForCabinets/rules",
            model: "infoForCabinets/model",
            columns: "infoForCabinets/columns",
        }),
    },
    methods: {
        ...mapActions({
            update: "infoForCabinets/update",
            show: "infoForCabinets/show",
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (res.status == 200) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
